import { MultiCountryData } from '../shared/models/multi-country-data';

export const environment = {
    production: false,
    version: '2.1.0.1-20240523-dev',
    test: 'develop',
    googleAnalytics: 'G-L2G7FL9MP3',
    googleAnalyticsTechnical: 'G-JX2GHBQLYG',
    rol: '',
    expressUserData: [],
    multiCountryData: [] as MultiCountryData[],
    basePath: '/',
    apigoogle: 'AIzaSyBLMW-yZQI4A_mnrDCugdzhMl1RPLjrJ4w',
    linkEntregaPage: 'https://develop.linkdepago.forzadelivery.com',
    apiLinkEntrega: 'https://backendlinkpago.forzadelivery.com/api/V1',
    apiBackApp: 'https://backendlinkpago.forzadelivery.com/app/api/V1',
    linkQrAfiliatePage: 'https://qa.portal.forzadelivery.com/clubForza/',
    storeLink: 'https://develop.tienda.forzadelivery.com',
};

export enum Constants {
    api = 'https://develop.portal.forzadelivery.com/fd/Home.aspx/API',
    sesionTime = 900000, // 15 * 60 * 1000 = 900000 milisegundos = 15 minutos
    sesionTimeExpress = 32400000, // 9 horas * 60 minutos  * 60 segundos * 1000 milisegundos = 32400000
}
